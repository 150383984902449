<template>
	<form @submit.prevent="$emit('submit')" autocomplete="off">
		<div class="form-group">
			<label for="name">Role name</label>
			<div class="input-group">
				<div class="input-group-prepend">
					<div class="input-group-text">
						<i class="fa fa-address-card"></i>
					</div>
				</div>
				<input :disabled="disabled" autocomplete="off" id="name" v-model="model.Name" name="name" placeholder="Name"
							 type="text"
							 required="required"
							 class="form-control">
			</div>
		</div>
		<div class="form-group">
			<label for="code">Code</label>
			<div class="input-group">
				<div class="input-group-prepend">
					<div class="input-group-text">
						<i class="fa fa-address-card"></i>
					</div>
				</div>
				<input :disabled="disabled" autocomplete="off" id="code" v-model="model.Code" name="code" placeholder="Code"
							 type="text"
							 required="required"
							 class="form-control">
			</div>
		</div>
		<div class="form-group">
			<label for="description">Description</label>
			<div class="input-group">
				<textarea :disabled="disabled" autocomplete="off" id="description" v-model="model.Description"
									name="description"
									placeholder="Description" type="text"
									class="form-control"></textarea>
			</div>
		</div>

		<hr>

		<h4>Permissions</h4>
		<permissions-editor :disabled="disabled" v-model="model.Permissions"></permissions-editor>

		<div class="form-group" v-if="!disabled">
			<button name="submit" type="submit" class="btn btn-primary">Save</button>
		</div>
	</form>
</template>

<script>
import PermissionsEditor from "../permissions/permissions-editor";

export default {
	components: {PermissionsEditor},
	props: {
		value: {
			default() {
				return {}
			},
			type: Object,
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			}
		}
	}
}
</script>
