<template>
	<div>
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item active">
					<router-link :to="{name: 'roles-index'}">Roles</router-link>
				</li>
				<li class="breadcrumb-item active">
					<router-link :to="{name: 'roles-new'}">New role</router-link>
				</li>
			</ol>
		</nav>

		<h3>New role</h3>

		<role-form v-model="model" @submit="save"></role-form>
	</div>
</template>

<script>
	import api from '@/api'
	import AgentProfileForm from "../../components/agents/agent-profile-form";
	import RoleForm from "../../components/roles/role-form";

	export default {
		components: {RoleForm, AgentProfileForm},
		data() {
			return {
				loading: false,
				model: {
					Name: null,
					Code: null,
					Description: null,
					Permissions: {}
				}
			}
		},
		methods: {
			async save() {
				this.loading = true
				this.model = await api.Roles.saveNew(this.model).finally(() => this.loading = false)
				this.$router.push({name: 'roles-edit-id', params: {id: this.model.Id}})
			}
		}
	}
</script>
