<template>
	<div class="d-flex flex-wrap justify-content-xl-start">
		<permission-checkbox :disabled="disabled" class="border permission mx-2 rounded bg-light my-2 p-3" v-model="_value[permission.code]"
							 :permission="permission"
							 v-for="permission in permissions" :key="permission.code"></permission-checkbox>
	</div>
</template>

<script>
	import api from '@/api'
	import PermissionCheckbox from "./permission-checkbox";

	export default {
		components: {PermissionCheckbox},
		props: {
			value: {
				type: Object,
				default() {
					return {}
				}
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				permissions: []
			}
		},
		computed: {
			_value: {
				get() {
					return this.value
				},
				set(val) {

					console.log('VAL', val)
					this.$emit('input', val)

				}
			}
		},
		async mounted() {
			this.permissions = await api.Permissions.getList()
			if (Array.isArray(this._value)) {
				this._value = {}
			}
		}
	}
</script>

<style scoped lang="scss">
	.permission {
		width: 200px;

		@media only screen and (max-width: 425px) {
			width: 100%;
		}
	}
</style>
