<template>
	<div class="row align-items-center" v-if="!disabled || !['none', null].includes(_value)">
		<div class="col-8">
			<h5 class="text-nowrap text-truncate" :title="permission.title" v-text="permission.title"></h5>
			<p class="text-small" v-text="permission.description"></p>
		</div>
		<div>
			<div class="badge" :class="{'badge-primary': _value === 'write', 'badge-success': _value === 'read'}" v-if="disabled">
				{{_value}}
			</div>
			<div class="form-check" v-for="option in options" v-else>
				<input class="form-check-input" type="radio" :name="`${permission.code}-option`"
					   :id="`${permission.code}-option-${option}`" :value="option" v-model="_value">
				<label class="form-check-label text-capitalize" :for="`${permission.code}-option-${option}`"
					   v-text="option"></label>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			value: {
				type: String,
				default: "none"
			},
			permission: {
				default: {},
				type: Object
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		mounted() {
			if (this._value === null) {
				this.$emit('input', this.permissions[0].code)
			}
		},
		computed: {
			_value: {
				get() {
					return this.value
				},
				set(val) {
					this.$emit('input', val)
				}
			}
		},
		data() {
			return {
				options: [
					"none", "read", "write"
				]
			}
		}
	}
</script>

<style scoped>

</style>
